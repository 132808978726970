import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Flex,
  Heading,
  Text,
  Image,
  TextLink,
  Grid,
  GridItem,
} from '@feast-it/pesto';
import { useInView } from 'react-intersection-observer';

import Mask from '../Mask';
import { BlobSVG1, BlobSVG2, BlobSVG3 } from './BlobSvgs';

import * as Styled from './styles';

const BLOBS = [BlobSVG1, BlobSVG2, BlobSVG3];

const MASKS = [
  '/images/generic/mask-1.svg',
  '/images/generic/mask-2.svg',
  '/images/generic/mask-3.svg',
];

const BlobImageAndText = ({
  heading,
  text,
  ctaText,
  ctaLink,
  image,
  reversed,
  shape,
  ...props
}) => {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const Shape = BLOBS[shape % MASKS.length];

  return (
    <Styled.BlobImageAndText {...props}>
      <Grid gridTemplateColumns={{ m: '1fr 1fr' }} gridGap={{ m: 'gutter.m' }}>
        <GridItem gridRow="1" gridColumn={reversed ? '2' : '1'}>
          <Flex
            flexDirection="column"
            justifyContent="center"
            height="100%"
            py={{ m: 9 }}
          >
            {heading && (
              <>
                <Heading
                  as="h3"
                  variant={{ _: 'headingMediumMobile', m: 'headingMedium' }}
                  color="blueDark"
                >
                  {heading}
                </Heading>

                <Box
                  width={85}
                  height={3}
                  bg="primary"
                  borderRadius={2}
                  mt={6}
                />
              </>
            )}

            {text && (
              <Text
                fontFamily="heading"
                color="blueDark"
                mt={6}
                whiteSpace="pre-line"
              >
                {text}
              </Text>
            )}

            {ctaLink && ctaText && (
              <Flex mt={2}>
                <TextLink
                  href={ctaLink}
                  fontFamily="heading"
                  color="primary"
                  arrow="right"
                >
                  {ctaText}
                </TextLink>
              </Flex>
            )}
          </Flex>
        </GridItem>

        <Box
          display={{ _: 'none', m: 'block' }}
          gridRow="1"
          gridColumn={reversed ? '1' : '2'}
        >
          {image && (
            <Flex flexDirection="column" justifyContent="center" height="100%">
              <Styled.Blob ref={ref}>
                <Styled.BlobImage>
                  <Mask src={MASKS[shape % MASKS.length]}>
                    <Image
                      src={image}
                      width={540}
                      height={440}
                      inView={inView}
                      imgix
                    />
                  </Mask>
                </Styled.BlobImage>

                <Shape fill="secondary" />
              </Styled.Blob>
            </Flex>
          )}
        </Box>
      </Grid>
    </Styled.BlobImageAndText>
  );
};

BlobImageAndText.defaultProps = {
  shape: 1,
};

BlobImageAndText.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
  shape: PropTypes.number.isRequired,
  reversed: PropTypes.bool,
};

export default BlobImageAndText;
