import React from 'react';
import { Box, Heading, Container, Image, columnWidth } from '@feast-it/pesto';
import { useInView } from 'react-intersection-observer';
import { useTheme } from 'emotion-theming';
import PropTypes from 'prop-types';

import BlobImageAndText from '../../components/BlobImageAndText';
import Mask from '../../components/Mask';

const WaveSvgDesktop = ({ fill }) => {
  const theme = useTheme();
  return (
    <svg viewBox="0 0 1440 464" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1440 464L0 464C0 464 376.504 288.577 753.004 345.489C1129.5 402.401 1440 0 1440 0V464Z"
        fill={theme.colors[fill]}
      />
    </svg>
  );
};

const BlobImageAndTextContainer = rawData => {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const data = {
    heading: rawData?.primary?.heading,
    mobileImage: rawData?.primary?.mobile_image?.url,
    items: rawData?.items,
  };

  return (
    <Box position="relative" as="section">
      <Box
        position="relative"
        zIndex="1"
        py={{ _: 9, m: 11 }}
        px={{ _: 'gutter._', s: 'gutter.s', m: 'gutter.m' }}
      >
        <Container maxWidth={columnWidth(10)}>
          <Heading
            as="h2"
            variant={{ _: 'headingExtraLargeMobile', m: 'headingExtraLarge' }}
            color="blueDark"
            textAlign={{ m: 'center' }}
            mb={9}
          >
            {data.heading}
          </Heading>

          {data.items.map((itemData, i) => {
            const item = {
              heading: itemData?.heading,
              text: itemData?.text,
              image: itemData?.['desktop-image']?.url,
              ctaText: itemData?.cta_text,
              ctaLink: itemData?.cta_link?.url,
            };

            const isLastItem = i + 1 === data.items.length;

            return (
              <BlobImageAndText
                key={`blob-image-and-text-${i}`}
                {...item}
                shape={i}
                reversed={i % 2}
                mb={isLastItem ? null : { _: 11, m: 0 }}
              />
            );
          })}
        </Container>
      </Box>

      {data.mobileImage && (
        <Box display={{ m: 'none' }} ref={ref}>
          <Mask src="/images/generic/wave-2.svg">
            <Image
              src={data.mobileImage}
              width={764}
              height={688}
              imgix
              inView={inView}
            />
          </Mask>
        </Box>
      )}

      <Box
        position="absolute"
        bottom="0"
        left="0"
        right="0"
        display={{ _: 'none', m: 'flex' }}
      >
        <WaveSvgDesktop fill="backDrop" />
      </Box>
    </Box>
  );
};

WaveSvgDesktop.propTypes = {
  fill: PropTypes.string,
};

export default BlobImageAndTextContainer;
