import styled from '@emotion/styled/macro';
import { space } from 'styled-system';
import { minWidth, rem } from '@feast-it/pesto';

export const BlobImageAndText = styled.article`
  ${space}

  ${({ theme }) => minWidth(theme.breakpoints.m)} {
    min-height: ${rem(400)};
  }
`;

export const Blob = styled.div`
  position: relative;
`;

export const BlobImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;
