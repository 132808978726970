import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';

export const BlobSVG1 = ({ fill }) => {
  const theme = useTheme();

  return (
    <svg viewBox="0 0 540 420" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M517.476 95.3319C591.139 245.849 469.916 385.537 358.566 414.43C278.608 435.177 111.41 395.151 55.0235 340.954C-1.36285 286.757 -12.146 160.818 12.365 95.3319C36.2048 31.639 96.5597 -9.26159 167.585 1.80415C238.611 12.8699 277.608 57.4683 352.862 59.9832C428.115 62.4982 488.797 36.7318 517.476 95.3319Z"
        fill={theme.colors[fill]}
      />
    </svg>
  );
};

export const BlobSVG2 = ({ fill }) => {
  const theme = useTheme();
  return (
    <svg viewBox="0 0 540 420" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M486.008 83.0526C538.341 134.427 552.106 224.093 529.582 294.098C508.581 359.369 441.273 390.438 379.682 413.511C332.545 431.169 284.656 407.492 234.254 404.731C163.44 400.853 82.5047 445.677 29.8771 394.355C-25.671 340.184 14.9965 248.309 6.11234 159.38C-2.77181 70.4507 62.6356 24.6053 123.27 4.72609C183.904 -15.1531 240.829 33.2663 303.116 46.7309C367.487 60.6459 437.561 35.4923 486.008 83.0526Z"
        fill={theme.colors[fill]}
      />
    </svg>
  );
};

export const BlobSVG3 = ({ fill }) => {
  const theme = useTheme();

  return (
    <svg viewBox="0 0 540 421" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M540 246.688C539.896 395.737 434.242 349.151 365.199 385.95C316.77 411.761 308.808 420.98 260 420.98C211.192 420.98 81.2202 384.798 54.2277 333.862C25.298 279.27 -15.9577 200.099 6.37775 142.43C31.6271 77.2368 80.1116 19.2311 147.808 4.45844C302.703 -14.5046 346.348 48.7433 400.447 81.2421C454.545 113.741 540.104 97.6393 540 246.688Z"
        fill={theme.colors[fill]}
      />
    </svg>
  );
};

BlobSVG1.propTypes = {
  fill: PropTypes.string,
};

BlobSVG2.propTypes = {
  fill: PropTypes.string,
};

BlobSVG3.propTypes = {
  fill: PropTypes.string,
};
